import request from "../utils/request";

export function getContractTypes(params) {
    return request({
        url: "contracttypes",
        method: "get",
        params,
    });
}

export function getAllContractTypes() {
    return request({
        url: "contracttypes",
        method: "get",
        params:{
            paginate: false,
        }
    });
}

export function addContractTypes(data) {
    return request({
        url: "contracttypes",
        method: "post",
        data,
    });
}

export function editContractTypes(data) {
    return request({
        url: "contracttypes",
        method: "put",
        data,
    });
}

export function deleteContractTypes(id) {
    return request({
        url: `/contracttypes/${id}`,
        method: "delete",
    });
}