<template>
  <PageListLayout
    icon="mdi-tag-multiple"
    title="Hợp đồng"
    subTitle="Danh sách hợp đồng"
    useAdd
    titleAdd="Thêm hợp đồng"
    @add="createItem"
    @reset-filter="resetFilter"
  >
    <template slot="side-right">
      <div class="d-flex">
        <div class="mr-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="updateExtend"
                :loading="loadingExtend"
              >
                <v-icon>mdi-calendar-refresh-outline</v-icon>
              </v-btn>
            </template>
            <span>Cập nhật hợp đồng gia hạn</span>
          </v-tooltip>
        </div>
        <div class="mr-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="exportContractsByRoles"
                :loading="loadingExportContractsByRoles"
              >
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
            </template>
            <span>Xuất hợp đồng tổng hợp</span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <template slot="filter">
      <div>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="formSearch.search"
          label="Tìm kiếm"
          clearable
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.customer_id"
          :items="customersList"
          label="Khách hàng"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          clearable
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  class="style_auto_complate"
                  :title="data.item.name"
                  v-html="data.item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.contract_type"
          :items="contractsList"
          label="Loại hợp đồng"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          clearable
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.status"
          :items="statusList"
          label="Trạng thái"
          item-text="name"
          item-value="value"
          outlined
          dense
          hide-details
          clearable
          multiple
        >
        </v-autocomplete>
      </div>
      <div class="mt-4">
        <v-menu
          v-model="showMenuDate1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formSearch.contract_date"
              append-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              label="Ngày hiệu lực"
              hide-details
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formSearch.contract_date"
            range
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="mt-4">
        <v-menu
          v-model="showMenuDate2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formSearch.contract_due_date"
              append-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              label="Ngày hết hạn"
              hide-details
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formSearch.contract_due_date"
            range
          ></v-date-picker>
        </v-menu>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.contract_date`]="{ item }">
        {{ dateFormat(item.contract_date) }}
      </template>
      <template v-slot:[`item.contract_due_date`]="{ item }">
        <div class="d-flex">
          {{ dateFormat(item.contract_due_date) }}
          <v-icon v-if="item.alert" class="ml-1 pointer" color="red">
            mdi-alert-decagram
          </v-icon>
        </div>
      </template>
      <template v-slot:[`item.contract_type`]="{ item }">
        <span>{{ getSourceName(contractsList, item.contract_type) }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span :class="`badge ${item.status && styleStatus[item.status]}`">{{
          getSourceName(statusList, item.status)
        }}</span>
      </template>
      <template v-slot:[`item.order`]="{ item }">
        <span>{{
          item.parent ? item.parent.order + "-" + item.order : item.order
        }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :loading="btnLoading == item.id"
            >
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="goEdit(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="goFollow(item)">
              <v-list-item-title>
                <v-icon class="mr-2">
                  {{
                    item.is_follow ? "mdi-eye-off-outline" : "mdi-eye-outline"
                  }}
                </v-icon>
                {{
                  item.is_follow ? "Dừng theo dõi" : "Theo dõi"
                }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
  </PageListLayout>
</template>
<script>
import {
  getContracts,
  deleteContracts,
  syncExtend,
  editContracts,
  updateFollow,
  exportContractTH,
  exportContractsByRoles,
} from "@/api/hopdong";
import { debounce } from "lodash";
import logo from "../../../assets/images/logoGreenStar.png";
import { getAllContractTypes } from "@/api/loaihopdong";
import { getCurrentExpiredDate } from "@/api/expireddate";
import KHACHHANG from "@/api/khachhang";
import { formatDate } from "@/utils/date";
import { STYLE_STATUS, STATUS_LIST } from "@/constants/hopdong";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      loadingExportContractsByRoles: false,
      loadingExportHD: false,
      loadingExtend: false,
      customersList: [],
      currentExpiredDate: {},
      formSearch: {},
      showMenuDate1: false,
      showMenuDate2: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      contractsList: [],
      btnLoading: -1,
      menu: {},
      loading: false,
      search: "",
      headers: [
        {
          text: "Mã hợp đồng",
          align: "start",
          sortable: false,
          value: "contract_code",
        },
        { text: "Ngày hiệu lực", value: "contract_date" },
        { text: "Ngày hết hạn", value: "contract_due_date" },
        { text: "Loại hợp đồng", value: "type.name" },
        { text: "Khách hàng", value: "customers.name" },
        { text: "Đại diện KH", value: "customer_agent_name" },
        {
          text: "Chức vụ đại diện KH",
          value: "customer_agent_position",
        },
        { text: "Mã số thuế KH", value: "customer_tax" },
        // { text: "Địa chỉ khách hàng", value: "customer_address" },
        // { text: "Số điện thoại khách hàng", value: "customer_phone" },
        { text: "Trạng thái", value: "status" },
        // { text: "Ghi chú", value: "description" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
      styleStatus: STYLE_STATUS,
      statusList: STATUS_LIST,
    };
  },
  computed: {},
  created() {
    this.getCurrentDate();
    this.getContractTypes();
    this.getAllList();
    this.getCustomersList();
  },

  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function(val) {
        this.page = 1;
        this.getAllList();
      }, 300),
    },
  },
  methods: {
    async exportContractsByRoles() {
      try {
        this.loadingExportContractsByRoles = true;
        const res = await exportContractsByRoles();
        saveAs(new Blob([res]), "Thông tin hợp đồng.xls");
      } catch (error) {
        this.$store.dispatch(
          "toastAlert/error",
          "Đã có lỗi xảy ra khi xuất hợp đồng"
        );
      } finally {
        this.loadingExportContractsByRoles = false;
      }
    },
    async exportHD() {
      try {
        this.loadingExportHD = true;
        const res = await exportContractTH(this.formSearch);
        saveAs(new Blob([res]), `Danh Sách Hợp đồng.xls`);
      } catch (error) {
        this.$store.dispatch("toastAlert/error", "Lỗi");
      } finally {
        this.loadingExportHD = false;
      }
    },
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
    async goFollow(item) {
      try {
        this.btnLoading = item.id;
        await updateFollow(item.id, {
          is_follow: !item.is_follow,
        });
        this.getAllList();
      } catch (error) {
        this.$store.dispatch("toastAlert/error", "Lỗi");
      } finally {
        this.btnLoading = -1;
      }
    },
    async updateExtend() {
      try {
        this.loadingExtend = true;
        await syncExtend();
        this.getAllList();
      } catch {
        this.$store.dispatch("toastAlert/error", "Lỗi");
      } finally {
        this.loadingExtend = false;
      }
    },
    async getContractTypes() {
      await getAllContractTypes().then((res) => {
        this.contractsList = res.map((item) => ({
          id: item.id,
          name: item.name,
        }));
      });
    },
    async getCustomersList() {
      let res = await KHACHHANG.getAllCustomers();
      this.customersList = res.data;
    },
    async getCurrentDate() {
      let res = await getCurrentExpiredDate();
      this.currentExpiredDate = res;
    },
    async getAllList() {
      this.loading = true;
      try {
        let data = await getContracts({
          page: this.page,
          perPage: this.itemsPerPage,
          ...this.formSearch,
        });
        this.tableData = data.data;
        this.tableData.forEach((x) => {
          if (x.expired_date == null) {
            x.expired_date = 0;
          }
          let status_due_date = this.compareDate(
            x.contract_due_date,
            x.expired_date
          );
          if (!x.is_follow) {
            x.status = 4;
          } else if (status_due_date == 1) {
            x.alert = true;
          } else if (status_due_date == 2) {
            x.status = 3;
          } else if (status_due_date == 4) {
            x.status = 5;
          }
        });
        console.log(this.tableData);
        this.pageCount = data.last_page;
      } finally {
        this.loading = false;
      }
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    compareDate(date, expired_date) {
      const today = new Date();
      date = new Date(date);
      const difference = date - today;
      let days;
      days = difference / (1000 * 3600 * 24);
      let currentTimeToCompare;

      if (this.currentExpiredDate.type == "Năm") {
        currentTimeToCompare = this.currentExpiredDate.value * 365;
      } else if (this.currentExpiredDate.type == "Tháng") {
        currentTimeToCompare = this.currentExpiredDate.value * 30;
      } else {
        currentTimeToCompare = this.currentExpiredDate.value;
      }

      if (days <= 7 && days > 0) {
        return 1;
      } else if (days < 0) {
        return 2;
      } else if (days <= currentTimeToCompare && days > 7) {
        return 4;
      }
      return 3;
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa sản phẩm",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa hợp đồng " +
          "<strong>" +
          item.contract_code +
          " ?" +
          "</strong>",
        action: () => {
          this.onDeleteContracts(item);
        },
        onDone: this.getAllList,
        disableMessage: true,
      });
    },
    async onDeleteContracts(item) {
      try {
        const res = await deleteContracts({ id: item.id, status: item.status });
        this.$store.dispatch("toastAlert/success", "Thành công");
      } catch (error) {
        this.$store.dispatch("toastAlert/error", "Không thể xóa hợp đồng");
      }
    },
    getSourceName(list, id) {
      let find = list.find((item) => item.value === id);
      return find ? find.name : null;
    },
    createItem() {
      this.$router.push({ path: "/kinhdoanh/themhopdong" });
    },
    goEdit(item) {
      this.$router.push({ path: `/kinhdoanh/themhopdong/${item.id}` });
    },
    dateFormat(e) {
      return formatDate(e);
    },
  },
};
</script>
<style scoped>
.style_auto_complate {
  max-width: 200px;
}
.bg-orange {
  background-color: orange;
}
</style>
