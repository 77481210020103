import request from "../utils/request";

export function getAllExpiredDate() {
  return request({
    url: "expired-date",
    method: "get",
  });
}
export function getCurrentExpiredDate() {
  return request({
    url: "current-expired-date",
    method: "get",
  });
}
export function addExpiredDate(data) {
  return request({
    url: "expired-date",
    method: "post",
    data,
  });
}

export function updateExpiredDate(data) {
  return request({
    url: "expired-date",
    method: "put",
    data,
  });
}

export function deleteExpiredDate(data) {
  return request({
    url: "/expired-date",
    method: "delete",
    data,
  });
}
